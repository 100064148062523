import { useState, useEffect, useRef } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import jwtdecode from 'jwt-decode';
import { useReactToPrint } from 'react-to-print';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Tag } from 'primereact/tag';
import '../theme/mystyles.css';

// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';

// components
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { addLocaleConfig } from '../services/utils';
import { getPublicAllSettings } from '../services/settings';
import { getCurrentUser } from '../services/auth.service';
import { getUserByLoginName } from '../services/user';
import { getAllMobils } from '../services/mobil';
import {
  createCashBoxByRangeDays,
  findFirstByOrderByVoucherNumberDesc,
  getCashBoxesByMobilAndDeadTimeDate
} from '../services/cashbox';

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

export default function Charge() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [disabledPaid, setDisabledPaid] = useState(true);
  const [isSubmittingCharge, setSubmittingCharge] = useState(false);
  const [cashboxes, setCashBoxes] = useState([]);
  const [open, setOpen] = useState(false);
  const [setting, setSettings] = useState();
  const [viewDate, setViewDate] = useState();
  const [dates, setDates] = useState([new Date()]);
  const [trueVlue, setTrueVlue] = useState(true);

  // FORMIK
  const LoginSchema = Yup.object().shape({
    deadTimeDate: Yup.date().nullable().required('End date is required'),
    mobil: Yup.object().nullable().required('Movil es Requerido')
  });

  const formik = useFormik({
    initialValues: {
      voucherNumber: 0,
      currentData: new Date(),
      deadTimeDate: new Date(),
      mobil: '',
      operator: null,
      amount: 0,
      cancelled: null,
      cancelledBy: null,
      user: null,
      manyVauchers: []
    },
    validationSchema: LoginSchema,
    onSubmit: (data) => {
      getTicketsByMobilAndDeadTime(data);
    }
  });

  const { errors, touched, values, handleSubmit, setFieldValue, handleBlur } = formik;

  // SET MOMENT "es"
  moment.locale('es');
  addLocale('es', addLocaleConfig);

  // GET USER, SETTINGS AND VOUCHER NUMBER
  useEffect(() => {
    const user = getCurrentUser();
    const userDecoded = jwtdecode(user);
    let mounted = true;
    getUserByLoginName(userDecoded.sub).then((items) => {
      if (mounted) {
        setFieldValue('user', items);
      }
    });
    getPublicAllSettings().then((items) => {
      if (mounted) {
        setSettings(items[0]);
        console.log(items[0]);
      }
    });
    findFirstByOrderByVoucherNumberDesc().then((item) => {
      if (mounted) {
        setFieldValue('voucherNumber', item.voucherNumber + 1);
      }
    });
    return () => (mounted = false);
  }, []);

  // GET ALL MOBILS
  const [mobils, setMobils] = useState([]);
  useEffect(() => {
    let mounted = true;
    getAllMobils().then((items) => {
      if (mounted) {
        const mobils = items.sort((a, b) => a.mobilCode - b.mobilCode);
        setMobils(mobils);
      }
    });
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    console.log('use effect by mobil updated');
    async function setInitialValues() {
      if (setting) {
        const amount = values.mobil.specialChargement ? setting.importTwo : setting.importOne;
        await setAmount(amount);
      }
    }
    setInitialValues();
  }, [values.mobil]);

  // GET TICKETS BY MOBIL AND DEADTIME
  const getTicketsByMobilAndDeadTime = (data) => {
    const cashbox = data;
    getCashBoxesByMobilAndDeadTimeDate(cashbox).then((items) => {
      setCashBoxes(items);
      console.log(items);
    });
  };

  // MAIN CHARGE
  const cashBoxExecute = () => {
    console.log('iniciando cobro..');
    setDisabledPaid(true);
    setSubmittingCharge(true);
    console.log(setting);
    let valid = true;

    if (setting.enabledrangetime) {
      valid = checkChargePermision();
    }

    if (valid) {
      const data = formik.values;
      const state = {
        id: 1,
        name: 'PAGADO'
      };
      data.state = state;
      data.currentData = moment.utc(data.currentData).format();
      data.deadTimeDate = moment(values.deadTimeDate).locale('es').format('YYYY-MM-DD');
      console.log(data);

      payByManyDay(data);
    } else {
      handleOpenDialogChargeNotPermision();
      setDisabledPaid(false);
      setSubmittingCharge(false);
    }
  };

  const payByManyDay = (data) => {
    const newarr = dates.sort((a, b) => moment(a).diff(b));
    const datesnew = newarr.map((date) => moment(date).locale('es').format('YYYY-MM-DD'));

    data.deadTimeDateList = datesnew;
    console.log(data);

    createCashBoxByRangeDays(data)
      .then((response) => {
        const reponses = response.data;
        console.log(reponses);

        if (reponses) {
          const cashboxesok = [];
          const responsesOk = reponses?.reduce((accumulator, response) => {
            if (response.code === 'OK') {
              accumulator += accumulator + 1;
              console.log(response.cashbox);
              cashboxesok.push(response.cashbox);
              enqueueSnackbar(response.message, {
                variant: 'success'
              });
            }

            if (
              response.code === 'NOK' &&
              response.error?.includes('unique_mobilid_deadtimedate')
            ) {
              enqueueSnackbar('Error: Ya existe este cobro', {
                variant: 'error'
              });
            } else if (response.code === 'NOK') {
              enqueueSnackbar(response.message, {
                variant: 'error'
              });
            }
            return accumulator;
          }, 0);

          console.log(cashboxesok);
          setFieldValue('manyVauchers', cashboxesok);
          console.log(responsesOk);

          if (responsesOk > 0) {
            handlePrint();
            getCashBoxesByMobilAndDeadTimeDate(data).then((items) => {
              setCashBoxes(items);
              console.log(items);
            });
          }

          setDisabledPaid(true);
          setDates([]);
          setSubmittingCharge(false);
        }
      })
      .catch((error) => {
        const { response } = error;
        console.log(response);
        console.log(response.data);
        if (
          response.statusText === 'Bad Request' &&
          response.data.detail?.includes('could not be parsed at index 0')
        ) {
          enqueueSnackbar('Error: debe seleccionar fecha', {
            variant: 'error'
          });
        }
        setDisabledPaid(true);
        setDates([]);
        setSubmittingCharge(false);
      });
  };

  // CHECK CHARGE PERMISION
  const checkChargePermision = () => {
    const today = moment();
    const dayofWeek = today.weekday();
    let result = false;
    if (dayofWeek === 6) {
      console.log('Cobro no permitido: es Domingo');
    } else {
      const format = 'hh:mm:ss';
      const time = moment(today, format);
      const beforeTime = moment('05:59:00', format);
      const afterTime = moment('16:00:00', format);
      if (time.isBetween(beforeTime, afterTime)) {
        result = true;
        console.log('Cobro permitido: es dia de semana');
      } else {
        console.log('Cobro no permitido: fuera de rango permitido');
      }
    }
    return result;
  };

  // UPDATE AMOUNT
  const setAmount = (tarifa) => {
    setFieldValue('amount', tarifa);
  };

  // DIALOG CHARGE NOT PERMISION
  const handleOpenDialogChargeNotPermision = () => {
    setOpen(true);
  };

  const handleCloseDialogChargeNotPermision = () => {
    setOpen(false);
  };

  // PRINTING TICKET
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  // HANDLE UPDATE MOBIL
  const handleUpdateMobil = (value) => {
    setFieldValue('mobil', value || '');
    if (value != null && values.deadTimeDate) {
      console.log('selected movil');
      const cashbox = {
        mobil: value,
        deadTimeDate: values.deadTimeDate
      };
      getTicketsByMobilAndDeadTime(cashbox);
    } else {
      setDisabledPaid(true);
    }

    if (dates && dates.length !== 0 && value !== null) {
      console.log('CON DATES!!');
      console.log(dates);
      setDisabledPaid(false);
    }
  };

  // HANDLE UPDATE DATE BY CLICK
  const handleOnChangeCalendar = (event) => {
    console.log(event);
    setDates(event.value);

    if (event.value?.length > 0) {
      setDisabledPaid(false);
    } else {
      setDisabledPaid(true);
    }
  };

  // HANDLE UPDATE DEADTIMEDATE BY RANGE
  const handleOnMonthChangeCalendar = (event) => {
    handleOnMonthChange(event.value);
    console.log(event);
    setViewDate(event.value);
  };

  // HANDLE ON MONTH CHANGE
  const handleOnMonthChange = (event) => {
    if (values.mobil) {
      console.log(event);
      const date = moment(event).locale('es').format('YYYY-MM-DD');
      const cashbox = {
        mobil: values.mobil,
        deadTimeDate: date
      };
      setFieldValue('deadTimeDate', date || null);
      console.log(cashbox);
      getTicketsByMobilAndDeadTime(cashbox);
    }
  };

  // DATE RANGE
  const paied = cashboxes
    .filter((cash) => {
      let paid;
      if (cash.state.name === 'PAGADO') {
        paid = cash;
      }
      return paid;
    })
    .map((cash) => cash.deadTimeDate);

  const anulated = cashboxes
    .filter((cash) => {
      let anulated;
      if (cash.state.name === 'ANULADO') {
        anulated = cash;
      }
      return anulated;
    })
    .map((cash) => cash.deadTimeDate);

  const notworked = cashboxes
    .filter((cash) => {
      let notworked;
      if (cash.state.name === 'NO_TRABAJO') {
        notworked = cash;
      }
      return notworked;
    })
    .map((cash) => cash.deadTimeDate);

  const dateTemplate = (date) => {
    const day = date.day.toString();
    const month = (date.month + 1).toString();

    const isEncontrado = dates?.some((date1) => {
      const onlyDay = moment(date1, 'YYYY-MM-DD').format('D');
      const onlyMonth = moment(date1, 'YYYY-MM-DD').format('M');
      return onlyDay === day && onlyMonth === month;
    });

    const checkStatus = (status, statusDates, className) => {
      if (statusDates.length > 0) {
        const statusDay = statusDates.find(
          (dayStatus) => moment(dayStatus, 'YYYY-MM-DD').format('D') === day
        );
        if (statusDay && !isEncontrado) {
          return (
            <span
              className={className}
              style={{
                borderRadius: '50%',
                width: '2.5rem',
                height: '2.5rem',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                color: 'white'
              }}
            >
              {date.day}
            </span>
          );
        }
      }
      return null;
    };

    const paidElement = checkStatus('pagado', paied, 'pagado');
    if (paidElement) return paidElement;

    const anulatedElement = checkStatus('anulado', anulated, 'anulado');
    if (anulatedElement) return anulatedElement;

    const notWorkedElement = checkStatus('no trabajado', notworked, 'no_trabajo');
    if (notWorkedElement) return notWorkedElement;

    if (isEncontrado) {
      return (
        <span
          className="selected"
          style={{
            borderRadius: '50%',
            width: '2.5rem',
            height: '2.5rem',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            color: 'white'
          }}
        >
          {date.day}
        </span>
      );
    }

    return date.day;
  };

  const stockBodyTemplate = (rowData) => {
    const stockClassName = classNames('font-bold', {
      'text-red-900': rowData.voucherNumber > 0
    });

    return (
      <div className={stockClassName} style={{ fontWeight: 'bold' }}>
        {rowData.voucherNumber}
      </div>
    );
  };

  const currentBodyTemplate = (cashbox) =>
    moment(cashbox.currentData).locale('es').format('DD-MM-YYYY HH:mm');

  const statusBodyTemplate = (cashbox) => (
    <Tag value={cashbox.state.name} className={getStyle(cashbox)} />
  );
  const getStyle = (cashbox) => {
    switch (cashbox.state.name) {
      case 'PAGADO':
        return 'pagado';
      case 'NO_TRABAJO':
        return 'no_trabajo';
      case 'ANULADO':
        return 'anulado';
      default:
        return null;
    }
  };

  return (
    <Page title="Cobro Frecuencia">
      <Container>
        <HeaderBreadcrumbs heading="Cobro de Frecuencia" />
        <Stack mb={7}>
          <Grid container spacing={3}>
            <Grid item md={7} xs={12}>
              <Card sx={{ py: 5, px: 5 }}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                      <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                        <Autocomplete
                          id="contact-autocomplete"
                          fullWidth
                          options={mobils}
                          getOptionLabel={(option) => option.mobilCode.toString()}
                          onChange={(e, value) => handleUpdateMobil(value)}
                          onOpen={handleBlur}
                          blurOnSelect
                          includeInputInList
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Nro. Móvil"
                              name="mobil"
                              error={Boolean(touched.mobil && errors.mobil)}
                              helperText={touched.mobil && errors.mobil}
                            />
                          )}
                        />
                      </Stack>
                      <Stack>
                        <Calendar
                          value={dates}
                          onChange={(e) => handleOnChangeCalendar(e)}
                          viewDate={viewDate}
                          onViewDateChange={(e) => handleOnMonthChangeCalendar(e)}
                          locale="es"
                          selectionMode="multiple"
                          inline
                          dateTemplate={dateTemplate}
                          showOtherMonths={false}
                          showButtonBar={trueVlue}
                        />
                      </Stack>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Card>
            </Grid>
            <Grid item md={5} xs={12}>
              <Card
                sx={{ border: 2, mb: 2 }}
                style={{ boxShadow: 'none', padding: 40 }}
                ref={componentRef}
              >
                <Typography variant="subtitle2" gutterBottom>
                  Costo Total:
                </Typography>

                <Stack spacing={2}>
                  <Typography variant="h3">
                    {values.manyVauchers?.reduce(
                      (accumulator, cashboxes) => accumulator + cashboxes.amount,
                      0
                    )}{' '}
                    u
                  </Typography>

                  <RowStyle>
                    <Typography variant="subtitle1">Fecha Emisión</Typography>
                    <Card
                      sx={{ border: 2, mb: 0 }}
                      style={{ boxShadow: 'none', paddingInline: 10 }}
                    >
                      <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        {moment(values.currentData).locale('es').format('DD-MM-YYYY HH:mm')}
                      </Typography>
                    </Card>
                  </RowStyle>

                  <RowStyle>
                    <Typography variant="subtitle1">Nro. Móvil</Typography>
                    <Typography variant="subtitle1">{values.mobil?.mobilCode}</Typography>
                  </RowStyle>

                  <RowStyle>
                    <Typography variant="subtitle1">Operador</Typography>
                    <Typography variant="subtitle1">{values.user?.login}</Typography>
                  </RowStyle>

                  <Divider variant="fullWidth" />

                  {values.manyVauchers
                    ?.sort((a, b) => a.voucherNumber - b.voucherNumber)
                    .map((cashboxes) => {
                      const { deadTimeDate, amount, voucherNumber } = cashboxes;
                      return (
                        <div>
                          <Stack mb={2}>
                            <RowStyle>
                              <Typography variant="subtitle1">Fecha Pago</Typography>
                              <Typography variant="subtitle1">
                                {moment(deadTimeDate).locale('es').format('DD-MM-YYYY')}
                              </Typography>
                            </RowStyle>
                            <RowStyle>
                              <Typography variant="body2">Monto</Typography>
                              <Typography variant="body2">
                                <Typography variant="body2">{amount}</Typography>
                              </Typography>
                            </RowStyle>
                            <RowStyle>
                              <Typography variant="body2">Comprobante</Typography>
                              <Typography variant="body2">{voucherNumber}</Typography>
                            </RowStyle>
                          </Stack>

                          <Divider variant="fullWidth" />
                        </div>
                      );
                    })}
                </Stack>
              </Card>
              <Stack direction="row" spacing={1.5}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  color="primarygreen"
                  loading={isSubmittingCharge}
                  disabled={disabledPaid}
                  onClick={() => cashBoxExecute()}
                >
                  Cobrar
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        <Stack mb={12}>
          <DataTable
            value={cashboxes}
            showGridlines
            paginator
            rows={50}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: '50rem' }}
            size="small"
            sortField="deadTimeDate"
            sortOrder={-1}
          >
            <Column field="amount" header="Total" />
            <Column field="status.name" header="Estado" body={statusBodyTemplate} />
            <Column field="deadTimeDate" header="Fecha Pago" sortable />
            <Column field="currentData" header="Fecha Emisión" body={currentBodyTemplate} />
            <Column field="mobil.mobilCode" header="Móvil" />
            <Column field="user.login" header="Operador" />
            <Column field="voucherNumber" header="Comprobante" sortable body={stockBodyTemplate} />
          </DataTable>
        </Stack>

        <Dialog
          open={open}
          onClose={handleCloseDialogChargeNotPermision}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ backgroundColor: 'red' }} id="alert-dialog-title">
            <Typography variant="h4" align="center" color="common.white">
              ACCIÓN NO PERMITIDA
            </Typography>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: 'red' }}>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="h5" align="center" color="common.white">
                No se permite el cobro de frecuencia fuera de horario
              </Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Container>
    </Page>
  );
}
